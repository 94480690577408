import './App.css';

import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <div className='landing'>
        <div className='content'>
          <img className='logo partThree' src={logo} />
          <div className='header'>
            <span className='partOne'>Building a better world,</span>
            <br/>
            <span className='partTwo'>one bit at a time.</span>
          </div>
          <span className='description partThree'>
            DappDash is a development lab building no-code tools for DApp 
            operators to manage and analyze their token-centered communities.
          </span>
          <br/>
          <a href='https://www.twitter.com/dappdash'><span className='join partThree'>follow us</span></a>
        </div>
      </div>
    </div>
  );
}

export default App;
